import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import { SESSION_KEYS, SITE_TECHNICAL_DATA } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { GlobalContextProps } from "src/components/GlobalContext/GlobalContext";
import { smartMeterFormSchema } from "src/schema/smartMeterFormSchema";
import SmartMeterForm from "src/components/Forms/SmartMeterForm/SmartMeterForm";
import { tariffSavings, TariffSavingsPayload } from "src/api/apiClients";
import { Tariff } from "src/models/clientsModel";
import { tariffSavingsMapped } from "src/dto/clientsModelMapped";
import { useState } from "react";
import usePayloadMapped from "src/dto/usePayloadMapped";

export enum SmartMeterTypes {
  JA = "true",
  NEIN = "false",
  BLANK = "",
}

export interface SmartMeterFormValues {
  smartMeterInstalled: SmartMeterTypes;
}

const SmartMeter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { siteTechnicalData: siteTechnicalDataFromStorage } =
    usePayloadMapped();

  const { setAnimations } = useOutletContext<GlobalContextProps>();

  const [getSiteTechnicalData, setSiteTechnicalData] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const [, setTariffs] = useSessionStorage<Tariff[]>(SESSION_KEYS.TARIFFS);

  const form = useForm<SmartMeterFormValues>({
    defaultValues: {
      smartMeterInstalled:
        (getSiteTechnicalData()?.smartMeterInstalled as SmartMeterTypes) ||
        SmartMeterTypes.BLANK,
    },
    mode: "onSubmit",
    resolver: yupResolver(smartMeterFormSchema),
  });

  const handleBackClick = () => {
    navigate(ROUTES.ANNUAL_CONSUMPTION);
  };

  const onSubmit = async (data: SmartMeterFormValues) => {
    setAnimations((prev) => ({ ...prev, continue: !prev.continue }));

    const siteTechnicalData = getSiteTechnicalData();
    const updatedSessionData = { ...siteTechnicalData, ...data };
    setSiteTechnicalData(updatedSessionData);

    const payload: TariffSavingsPayload = {
      siteTechnicalData: {
        pvInstalled: siteTechnicalDataFromStorage.pvInstalled,
        pvPowerMaxKwp: siteTechnicalDataFromStorage.pvPowerMaxKwp,
        wallboxInstalled: siteTechnicalDataFromStorage.wallboxInstalled,

        batteryInstalled: siteTechnicalDataFromStorage.batteryInstalled,
        batterySizeKwh: siteTechnicalDataFromStorage.batterySizeKwh,

        smartMeterInstalled: JSON.parse(data.smartMeterInstalled),
      },
      userProfileData: {
        yearlyConsumptionKwh: siteTechnicalDataFromStorage.yearlyConsumptionKwh,
        zipcode: siteTechnicalDataFromStorage.pvZipCode,
      },
    };

    setIsLoading(true);

    try {
      const response = await tariffSavings(payload);
      const responseMapped = tariffSavingsMapped(response);

      setTariffs(responseMapped);

      setTimeout(() => {
        setIsLoading(false);
        navigate(ROUTES.CALCULATED_SAVINGS);
      }, 1000);
    } catch (e) {
      setIsLoading(false);
      // TODO: check design for error case
      alert("transferId missing");
    }
  };

  return (
    <FormContainer
      title={t("page.smartMeter.formTitle")}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Box sx={{ marginTop: 6, marginBottom: 3 }}>
        <SmartMeterForm form={form} />
      </Box>
      <FormSubmitContainer onClick={handleBackClick} isLoading={isLoading} />
    </FormContainer>
  );
};

export default SmartMeter;
